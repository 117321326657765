class FormClientModel {
	constructor({ form_created_on, form_description, form_enabled, form_name, form_uuid, form_version, form_version_created_on, form_version_def, form_version_deprecated_on, form_version_description, form_version_published_on, form_version_uuid, form_data_uuid, form_data}){
		this.form_created_on = form_created_on;
		this.form_description = form_description;
		this.form_enabled = form_enabled;
		this.form_name = form_name;
		this.form_uuid = form_uuid;
		this.form_version = form_version;
		this.form_version_created_on = form_version_created_on;
		this.form_version_def = form_version_def;
		this.form_version_deprecated_on = form_version_deprecated_on;
		this.form_version_description = form_version_description;
		this.form_version_published_on = form_version_published_on;
		this.form_version_uuid = form_version_uuid;
		this.form_data_uuid = form_data_uuid;
		this.form_data = form_data;
	}

	async fetchForm(){
		let form = await Grape.fetches.fetchJSON(`/forms/api/forms/find?form_data_uuid=${this.form_data_uuid}&form_version_uuid=${this.form_version_uuid}&form_uuid=${this.form_uuid}&form_name=${this.form_name}&form_version=${this.form_version}`);

		if (form.status === 'OK'){
			this.form_created_on = form.form.form_created_on;
			this.form_description = form.form.form_description;
			this.form_enabled = form.form.form_enabled;
			this.form_name = form.form.form_name;
			this.form_uuid = form.form.form_uuid;
			this.form_version = form.form.form_version;
			this.form_version_created_on = form.form.form_version_created_on;
			this.form_version_def = form.form.form_version_def;
			this.form_version_deprecated_on = form.form.form_version_deprecated_on;
			this.form_version_description = form.form.form_version_description;
			this.form_version_published_on = form.form.form_version_published_on;
			this.form_version_uuid = form.form.form_version_uuid;
			this.form_data_uuid = form.form.form_data_uuid;
			this.form_data = form.form.form_data
		}

		return form;
		// if a form_uuid get form_def using that and latest version
		// if a form_version_uuid get form_def using that
		// if form_data_uuid is provided get the form_def and then populate it with the data
	};

	saveFormData(formData, quiet=false){
		//save form data create or update
		// console.log(this.formData.form());
		let payload = {
			form_data_uuid: this.form_data_uuid,
			form_version_uuid: this.form_version_uuid,
			form_data: formData
		};

		return Grape.fetches.postJSON('/forms/api/form/data/save', payload).then((d)=>{
			if (d.status === 'OK'){
				this.form_data_uuid = d.form_data.form_data_uuid;
				if (!quiet)
					Grape.alerts.alert({type:'success', title:'Saved', message:"Saved"});
			}
			else
				Grape.alerts.apiError(d);
		});

		// if (!this.form_data_uuid) {
		// 	return Grape.fetches.postJSON('/forms/api/form/data/add', payload).then((d)=>{
		// 		if (d.status === 'OK'){
		// 			if (!quiet)
		// 				Grape.alerts.alert({type:'success', title:'Saved', message:"Saved"});
		// 		}
		// 		else
		// 			Grape.alerts.apiError(d);
		// 	});
		// } else {
		// 	return Grape.fetches.putJSON(`/forms/api/form/data/edit/${payload.form_data_uuid}`, payload).then((d)=>{
		// 		if (d.status === 'OK'){
		// 			if (!quiet)
		// 				Grape.alerts.alert({type:'success', title:'Saved', message:"Saved"});
		// 		}
		// 		else
		// 			Grape.alerts.apiError(d);
		// 	})
		// }
	};

	submitFormData(){
		//submit form data same as above but will lock form data
	};

	serialize(){
		return {
			form_created_on : this.form_created_on,
			form_description : this.form_description,
			form_enabled : this.form_enabled,
			form_name : this.form_name,
			form_uuid : this.form_uuid,
			form_version : this.form_version,
			form_version_created_on : this.form_version_created_on,
			form_version_def : this.form_version_def,
			form_version_deprecated_on : this.form_version_deprecated_on,
			form_version_description : this.form_version_description,
			form_version_published_on : this.form_version_published_on,
			form_version_uuid : this.form_version_uuid
		}
	}

}


module.exports = FormClientModel;
