const Expression = require('./Expression.js');

Expression.EffectRegistry.set('customErrMsg', "", "", {}, (context, result, message)=>{
	if (result instanceof Expression.ExpressionResult){
		if (!result.result)
			result.message = message;
	}
});

let preprocessValidator = (definition, value, placeholder="{{value}}")=>{
	let processedDefinition;
	let type;
	if (typeof definition === 'object'){
		type = Array.isArray(definition) ? 'array' : 'object';
	}
	else {
		type = typeof definition;
	}

	switch (type){
		case 'object':
			processedDefinition = {};
			for (let key of Object.keys(definition)){
				processedDefinition[key] = preprocessValidator(definition[key], value, placeholder);
			}
			break;
		case 'array':
			processedDefinition = [];
			for (let index in definition){
				processedDefinition.push(preprocessValidator(definition[index], value, placeholder));
			}
			break;
		case 'string':
			if (definition === placeholder)
				processedDefinition = value;
			else if (definition.includes(placeholder)){
				processedDefinition = definition.replace(placeholder, value);
			}
			else
				processedDefinition = definition;
			break;
		default:
			processedDefinition = definition;
	}
	return processedDefinition;
}

let buildValidator = (definition)=>{
	return (context, value)=>{
		let processedDef = preprocessValidator(definition, value);
		return (Expression.buildExpression(processedDef))(context);
	}
}

let buildFieldValidator = (fieldObs)=>{
	let fieldDef = fieldObs();
	let validator = buildValidator(fieldDef.validator);
	return (context, value)=>{
		context ??= {};
		if (value !== undefined)
			return validator(Object.assign({fieldObs}, context), value);
		return validator(Object.assign({fieldObs}, context), fieldObs('value'));
	}
}

module.exports = {buildValidator, buildFieldValidator};
