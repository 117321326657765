import template from './ko-panel.html';

class ComponentVM {
	constructor(params) {
		this.formData = params.formData;
		this.uiScope = params.uiScope;
		this.data = ko_helper.safe_observable(params.data);
		this.showDebug = ko_helper.safe_observable(params.showDebug);
		// console.log('params.data', params.data);
		// this.data = ko_helper.safe_observable(params.data || {});
		// console.log('params.data', params.data);
		// console.log('params.data.properties', params.data.properties);
		// this.properties = ko_helper.safe_observable(params.data.properties || {});
		// console.log('this.properties()',this.data());
		// this.gridDisplay = ko.observable(this.data().properties.gridDisplay);
		// this.gridColumns = ko.observable(this.data().properties.gridColumns);
		// this.gridRows = ko.observable(this.data().properties.gridRows);
		// this.gridColumn = ko.observable(this.data().properties.gridColumn);
		// this.gridRow = ko.observable(this.data().properties.gridRow);
	}

};

window.FormsClient.registerUIComponent({type:'panel',ui_component:{ name:'ko-panel', schema:{
	"$schema": "forms/panel",
	"$id": "forms-panel",
	"title": "Panel Component",
	"description": "An panel container component for forms",
	"type": "object",
	"required": ["type", "properties"],
	"properties": {
		"type": {
			"type": "string",
			"description": "The component type"
		},
		"properties": {
			"type": "object",
			"description": "The component properties",
			"properties": {
				"title": {
					"type": "string",
					"description": "Panel title text",
					"default": ""
				},
				"gridColumns": {
					"type": "string",
					"description": "Supported css gridTemplateColumns value for a gridDisplay",
					"default": ""
				},
				"gridRows": {
					"type": "string",
					"description": "Supported css gridTemplateRows value for a gridDisplay",
					"default": ""
				},
				"gridColumn": {
					"type": "string",
					"description": "Supported css gridColumn value for positioning inside a gridDisplay",
					"default": ""
				},
				"gridRow": {
					"type": "string",
					"description": "Supported css gridRow value for positioning inside a gridDisplay",
					"default": ""
				}
			}
		}
	}
}}});

export default {
	name: 'ko-panel',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
