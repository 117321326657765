import template from './form.html';

class FormVM
{
	constructor(page)
	{
		this.page = page;
		this.form = ko.observable({});
		this.editMode = ko.observable(false);

		this.form = ko.observable();

	}
}

//Handle Interaction with Page
class FormPage
{
	constructor(bindings)
	{
		this.viewModel = new FormVM(this);
		this.viewModel.form({
			form_uuid: bindings.form_uuid,
			form_version_uuid: bindings.form_version_uuid,
			form_data_uuid: bindings.form_data_uuid,
			form_name: bindings.form_name,
			form_version: bindings.form_version
		});
	}

	async init () {
		document.title = 'Form';
	}

	async updateData ()
	{

	}
}

export default {
	route: '[/]forms/client/form/:form',
	page_class: FormPage,
	template: template
};
