import template from './ko-select.html';
import { observable2 } from '../lib/Observables';

class ComponentVM {
	self;
	constructor(params) {
		self = this;

		this.error = ko.observable();

		this.formData = params.formData;
		this.uiScope = params.uiScope;
		this.context = ko_helper.safe_observable(params.context);
		this.formComponent = params.formComponent;
		this.label = ko.observable(this.formComponent.label || '');
		this.fieldInstance = this.context().form.getFieldInstance(this.formComponent.field);// get the fuel
		this.value = FormsClient.psFormKoObservable({observable: this.fieldInstance.value, ref:null, owner:this});
		this.valid = FormsClient.psFormKoObservable({observable: this.fieldInstance.valid, ref:null, owner:this});
		this.messages = FormsClient.psFormKoObservable({observable: this.fieldInstance.messages, ref:null, owner:this});
		this.warningMessages = FormsClient.psFormKoObservable({observable: this.fieldInstance.warningMessages, ref:null, owner:this});
		this.enabled = FormsClient.psFormKoObservable({observable: this.fieldInstance.enabled, ref:null, owner:this});
		this.visible = FormsClient.psFormKoObservable({observable: this.fieldInstance.visible, ref:null, owner:this});
		this.fieldMessages = ko.computed(()=>{
			let messages = this.messages();
			if (messages && messages.length && messages.length > 0)
				return JSON.stringify(messages);
			return '';
		})

		this.fieldWarningMessages = ko.computed(()=>{
			let warningMessages = this.warningMessages();
			if (warningMessages && warningMessages.length && warningMessages.length > 0)
				return JSON.stringify(warningMessages);
			return '';
		})

		this.optionsText = ko.observable(this.formComponent.optionsText || 'text');
		this.optionsValue = ko.observable(this.formComponent.optionsValue || 'value');
		this.optionsCaption = ko.observable(this.formComponent.optionsCaption);

		// todo make more dynamic for when options change
		if (this.formComponent.options[0] && typeof this.formComponent.options[0] === 'string'){
			let options = [];
			for (let optionText of this.formComponent.options){
				let option = {};
				option[this.optionsText()] = optionText;
				option[this.optionsValue()] = optionText;
				options.push(option);
			}
			this.options = ko.observableArray(options);
		} else {
			this.options = ko.observableArray(this.formComponent.options || []);
		}

		this.api = ko.observable(this.formComponent.api);
		this.grapeTables = ko.observable(this.formComponent.grapeTables);
		this.grapeCache = ko.observable(this.formComponent.grapeCache);


		//api
		// TODO should come from main observable for ui data
		if (this.api()){
			this.api.subscribe((newValue)=>{
				this.fetchOptionsFromApi(newValue)
			});
		}

		//grape tables
		// TODO should come from main observable for ui data
		if (this.grapeTables()){
			this.grapeTables.subscribe((newValue)=>{
				this.fetchOptionsFromGrapeTables(newValue);
			});
		}

		//grape cache
		if (this.grapeCache()){
			this.grapeCache.subscribe((newValue)=>{
				this.fetchOptionsFromGrapeCache();
			});
		}
	}

	fetchOptionsFromApi(api){
		Grape.fetches.fetchJSON(api.url)
		.then((res)=>{
			if (res?.status !== 'OK'){
				this.error('API for select options Failed');
			}

			let field = api.field || 'records'
			this.options(res?.[field]||[]);
		});
	}

	fetchOptionsFromGrapeTables(grapeTables){
		Grape.tables.select(grapeTables).then((res)=>{
			if (res?.status !== 'OK'){
				this.error('Grape Tables for select options Failed');
			}

			let field = grapeTables.field || 'records'
			this.options(res?.[field]||[]);
		});
	}

	fetchOptionsFromGrapeCache(){
		Grape.cache.fetch(this.grapeCache().name).then((res)=>{
			if (!res){
				this.error('Grape Cache for select options Failed');
			}
			this.options(res||[]);
		});
	}

};

window.FormsClient.registerUIComponent({type:'select',ui_component:{ name:'ko-select', schema:{}}});

export default {
	name: 'ko-select',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
