
/* Application entry-point */

import './css/forms_client.css';
import './lib/ko.bindingHandlerElement.js';

import pages from './pages/index.js';
import dialogs from './dialogs/index.js';
// import Plugin from './lib/FormsClientPlugin.js';
import components from './components/index.js';

window.Grape.pages.registerPages(pages);
window.Grape.dialog.registerDialogs(dialogs);
window.Grape.component.registerComponents(components);

/* "main" */
document.addEventListener('DOMContentLoaded', () => {
	// Grape.plugins.register(Plugin);
});
