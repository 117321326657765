// create a instance of a field and its.field with methods to get/set/observe its.field;
const Validator = require('./Validator.js');

class Field {
	constructor(fieldObservableRef, context){
		this.context = context;
		this.field = fieldObservableRef;
		this.ui = this.field.getObservableRef('ui');
		this.index = this.field.getObservableRef('index');
		this.targetPath = this.field.getObservableRef('targetPath');
		this.value = this.field.getObservableRef('value');
		this.validator = this.field.getObservableRef('validator');
		this.extraMessages = this.field.getObservableRef('extraMessages');
		this.valid = this.field.getObservableRef('valid');
		this.messages = this.field.getObservableRef('messages');
		this.warningMessages = this.field.getObservableRef('warningMessages');
		this.enabled = this.field.getObservableRef('enabled');
		this.visible = this.field.getObservableRef('visible');
		this.properties = this.field.getObservableRef('properties');
		this.items = this.field.getObservableRef('items');

		this.fieldValidator;
		this.extraMessagesValidator;

		this.init();

		this.subscriptions = {};
	}

	init(defaultOverrides={}){
		let fieldDefaults = {
			"ui": null,
			"label": "",
			"settings": {},
			"value": null, // TODO different default for different types
			"validator": {"expFunc": "true"},
			"extraMessages": {"expFunc": "true"},
			"valid": false,
			"messages": "",
			"warningMessages": "",
			"enabled": true,
			"visible": true
		}
		let fieldDef = this.field.observable.getValue(`${this.field.ref}`, false);
		this.field.observable.setValue(`${this.field.ref}`, Object.assign({}, fieldDefaults, defaultOverrides, fieldDef));

		this.initFieldValidator();
	};

	initFieldValidator(){
		this.fieldValidator = Validator.buildValidator(this.validator());
		this.extraMessagesValidator = Validator.buildValidator(this.extraMessages());
		this.validate()
		this.field.subscribe('value', (newValue)=>{
			this.validate(newValue);
		});
	};

	fieldContext(){
		return Object.assign({field:this}, this.context);
	};

	validate(value){// TODO handle result types
		// console.log("validating: ", this.targetPath, this.value);
		value ??= this.value();
		let validationResult = this.fieldValidator(this.context, value);
		let extraMessagesResult = this.extraMessagesValidator(this.context, value);
		this.valid(validationResult.result);
		this.messages(validationResult.message);
		this.warningMessages(extraMessagesResult.message);

		if ((this.valid() && this.messages().length === 0) && (!extraMessagesResult.result && extraMessagesResult.message !== '')){
			this.valid(extraMessagesResult.result);
			this.messages(validationResult.message);
			this.warningMessages(extraMessagesResult.message);
		}

		if ((!this.valid() && this.messages().length !== 0) && (extraMessagesResult.result && extraMessagesResult.message === '')){
			this.valid(validationResult.result);
			this.messages(validationResult.message);
			this.warningMessages(extraMessagesResult.message);
		}

	};

	subscribe(prop, func){
		if (!this.subscriptions.hasOwnProperty('prop'))
			this.subscriptions[prop] = [];
		this.subscriptions[prop].push(func);

	}

	runSubscriptions(prop){
		for (let func of this.subscriptions[prop] || []){
			func(this[prop]);
		}
	}

	setValue(newValue){
		this.value(newValue);
	}
};

class ObjectField extends Field {
	constructor(fieldObservableRef, context){
		super(fieldObservableRef, context)

		this.fieldInstances = {};
		this.initFieldInstances();
	};

	init(){
		super.init({properties:{}, value:{}});
	};

	initFieldValidator(){
		super.initFieldValidator();
	};

	fieldContext(){
		let context = super.fieldContext()
		return context;
	};

	validate(){
		super.validate();
	};

	initFieldInstances(){
		let fields = this.field.getValue('properties', false);
		for (let [i, field] of Object.entries(fields)){
			let newFieldInstance;
			if (field.type === 'object'){
				newFieldInstance = createObjectField(this.field.getObservableRef(`properties[${i}]`), this.context);
				this.fieldInstances[field.name] = (newFieldInstance);
			} else if (field.type === 'list') {
				newFieldInstance = createListField(this.field.getObservableRef(`properties[${i}]`), this.context)
				this.fieldInstances[field.name] = newFieldInstance;
			} else {
				newFieldInstance = createField(this.field.getObservableRef(`properties[${i}]`), this.context);
				this.fieldInstances[field.name] = newFieldInstance;
			}

			this.updateValue(field.name, newFieldInstance.value());
			newFieldInstance.value.subscribe((newValue)=>{this.updateValue(field.name, newValue)});
		}
	}

	updateValue(fieldName, newValue){ // rebuild each time or just update specific changed field?
		let obj = this.value();
		obj[fieldName] = newValue;
		this.value(obj);
	}

	setValue(newValue){
		if (typeof newValue !== 'object' && Array.isArray(newValue))
			throw new Error('New value must be an object');
		for (let key of Object.keys(newValue)){
			this.fieldInstances[key].setValue(newValue[key]);
		}
	}
};

class ListField extends Field {
	constructor(fieldObservableRef, context){
		super(fieldObservableRef, context);

		this.fieldInstances = [];
		this.initFieldInstances();
	};

	init(){
		super.init({properties:[], items:{}, value:[]});
	};

	initFieldValidator(){
		super.initFieldValidator();
	};

	fieldContext(){
		let context = super.fieldContext()
		return context;
	};

	validate(){
		super.validate();
	};

	initFieldInstances(){
		let items = this.field.getValue('properties', false);
		for (let i in items){
			if (items[i].type === 'object'){
				let newObjectField = createObjectField(this.field.getObservableRef(`properties[${i}]`), this.context);
				this.fieldInstances.push(newObjectField);
			} else if (items[i].type === 'list') {
				let newListField = createListField(this.field.getObservableRef(`properties[${i}]`), this.context)
				this.fieldInstances.push(newListField);
			} else {
				this.fieldInstances.push(createField(this.field.getObservableRef(`properties[${i}]`), this.context));
			}
		}
	}

	addItem(definition={}){
		// get the template def form items make a copy and add it into properties then create field instance
		let itemDef = Object.assign({}, this.field.getValue('items'), definition);

		let index = this.field.getValue('properties[]').length;
		itemDef.index = index;

		this.field.setValue({ref: `properties[${index}]`, newValue: itemDef});

		let fieldObs = this.field.getObservableRef(`properties[${index}]`);
		let field = fieldObs();

		let newFieldInstance;
		if (field.type === 'object'){
			newFieldInstance = createObjectField(fieldObs, this.context);
			this.fieldInstances.push(newFieldInstance);
		} else if (field.type === 'list') {
			newFieldInstance = createListField(fieldObs, this.context);
			this.fieldInstances.push(newFieldInstance);
		} else {
			newFieldInstance = createField(fieldObs, this.context);
			this.fieldInstances.push(newFieldInstance);
		}

		this.updateValue(index, newFieldInstance.value());
		newFieldInstance.value.subscribe((newValue)=>{
			this.updateValue(index, newValue);
		})

		return newFieldInstance;
	}

	updateValue(index, newValue){
		let list = this.value();
		list[index] = newValue;
		this.value(list);
	}

	push(){

	};

	pop(){

	};

	splice(start, deleteCount, ...items){
		this.fieldInstances.splice(start, deleteCount, ...items);
		this.properties.splice(start, deleteCount, ...items);

		// rebuild list field value
		let value = [];
		for (let index in this.fieldInstances){
			value.push(this.fieldInstances[index].value());
		}
		this.value(value);
	};

	setValue(newValue){
		if (typeof newValue !== 'object' && !Array.isArray(newValue))
			throw new Error('New value must be an array');
		// TODO clear list first
		this.clear();
		for (let index in newValue){
			let newField = this.addItem();
			newField.setValue(newValue[index]);
		}
	}

	clear(){
		return this.splice(0, this.fieldInstances.length);
	}
};

function createListField(fieldObservableRef, context){
	return new ListField(fieldObservableRef, context)
}

function createObjectField(fieldObservableRef, context){
	return new ObjectField(fieldObservableRef, context)
}

function createField(fieldObservableRef, context){
	return new Field(fieldObservableRef, context)
}

module.exports = {Field, createField, createObjectField, createListField};
