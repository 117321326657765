// a rule could be class that contains all the data related to that rule for example dependencies

const Expression = require('./Expression.js');

let preprocessRule = (definition, value, placeholder="{{value}}")=>{
	let processedDefinition;
	let type;
	if (typeof definition === 'object'){
		type = Array.isArray(definition) ? 'array' : 'object';
	}
	else {
		type = typeof definition;
	}

	switch (type){
		case 'object':
			processedDefinition = {};
			for (let key of Object.keys(definition)){
				processedDefinition[key] = preprocessRule(definition[key], value, placeholder);
			}
			break;
		case 'array':
			processedDefinition = [];
			for (let index in definition){
				processedDefinition.push(preprocessRule(definition[index], value, placeholder));
			}
			break;
		case 'string':
			if (definition === placeholder)
				processedDefinition = value;
			else if (definition.includes(placeholder)){
				processedDefinition = definition.replace(placeholder, value);
			}
			else
				processedDefinition = definition;
			break;
		default:
			processedDefinition = definition;
	}
	return processedDefinition;
}

let buildRule = (definition)=>{
	return (context, value)=>{
		// let processedDef = preprocessRule(definition, value);
		let processedDef = definition;
		return (Expression.buildExpression(processedDef))(context);
	}
}

module.exports = buildRule;
