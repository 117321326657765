import template from './ko-datetime-picker.html';
import FormsClient from '../lib/FormsClient';

class ComponentVM {
	constructor(params) {
		this.context = ko_helper.safe_observable(params.context);
		this.formComponent = params.formComponent;
		this.label = ko.observable(this.formComponent.label || '');
		this.settings = ko.observable(this.formComponent.settings || {});
		this.fieldInstance = this.context().form.getFieldInstance(this.formComponent.field);// get the fuel
		this.value = FormsClient.psFormKoObservable({observable: this.fieldInstance.value, ref:null, owner:this});
		this.valid = FormsClient.psFormKoObservable({observable: this.fieldInstance.valid, ref:null, owner:this});
		this.messages = FormsClient.psFormKoObservable({observable: this.fieldInstance.messages, ref:null, owner:this});
		this.warningMessages = FormsClient.psFormKoObservable({observable: this.fieldInstance.warningMessages, ref:null, owner:this});
		this.enabled = FormsClient.psFormKoObservable({observable: this.fieldInstance.enabled, ref:null, owner:this});
		this.visible = FormsClient.psFormKoObservable({observable: this.fieldInstance.visible, ref:null, owner:this});
		this.fieldMessages = ko.computed(()=>{
			let messages = this.messages();
			if (messages && messages.length && messages.length > 0)
				return JSON.stringify(messages);
			return '';
		})

		this.fieldWarningMessages = ko.computed(()=>{ //input
			let warningMessages = this.warningMessages();
			if (warningMessages && warningMessages.length && warningMessages.length > 0)
				return JSON.stringify(warningMessages);
			return '';
		})

	}

};

window.FormsClient.registerUIComponent({type:'datetime-picker',ui_component:{ name:'ko-datetime-picker', schema:{
	"$schema": "forms/datetime-picker",
	"$id": "forms-datetime-picker",
	"title": "Datetime-picker Component",
	"description": "A datetime-picker component for forms",
	"type": "object",
	"required": ["type", "properties"],
	"properties": {
		"type": {
			"type": "string",
			"description": "the component type"
		},
		"properties": {
			"type": "object",
			"description": "The component properties",
			"required": ["scope"],
			"properties": {
				"scope": {
					"type": "string",
					"description": "A reference string with a path to the variable storing the value"
				},
				"visible": {
					"type": "boolean",
					"description": "Control component visibility",
					"default": true
				},
				"disabled": {
					"type": "boolean",
					"description": "Disable component",
					"default": false
				},
				"label": {
					"type": "string",
					"description": "The component label",
					"default": ""
				},
				"showLabel": {
					"type": "boolean",
					"description": "Show component label",
					"default": false
				},
				"type": {
					"type": "string",
					"description": "Supported html input element type",
					"default": "text"
				},
				"gridColumn": {
					"type": "string",
					"description": "Supported css gridColumn value for positioning inside a gridDisplay",
					"default": ""
				},
				"gridRow": {
					"type": "string",
					"description": "Supported css gridRow value for positioning inside a gridDisplay",
					"default": ""
				}

			}
		}
	}
}}}); // register the name used in the ui model to map to the name of the actual ko component

export default {
	name: 'ko-datetime-picker',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
