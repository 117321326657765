
import template from './form_dialog.html';
import FormClientModel from '../lib/models/FormClientModel';

/**
 * View Model
 */
class FormDialogVM
{
	/**
	 * ViewModel constructor.
	 * @param {FormDialogClass} dialog - Dialog class
	 */
	constructor(dialog)
	{
		this.dialog = dialog;
		let def = dialog.bindings;
		if (dialog.bindings.fields)
			def = {form_version_def: dialog.bindings};

		this.formModel = new FormClientModel(def);

		this.def = ko_helper.safe_observable(this.formModel.form_version_def);
		this.data = ko_helper.safe_observable(this.formModel.form_data);
		this.form = ko.observable();
		this.options = ko_helper.safe_observable(dialog.bindings.options || {});

		if (!this.formModel.form_version_def){
			this.formModel.fetchForm().then((res)=>{
				this.def(this.formModel.form_version_def);
			});
		}
	}

	save()
	{
		let data = this.form().getData();
		this.formModel.saveFormData(data);
	}

	submit()
	{
		let data = this.form().getData();
		this.formModel.submitFormData(data);
		// if uuids then save to that and return all data on close
		// call form client model submit
		this.dialog.close(data);
	}

	close()
	{
		this.dialog.close(false);
	}
}

/**
 * Dialog
 */
class FormDialogClass
{
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new FormDialogVM(this); // Name of the ViewModel defined above
	}

	get name() { return 'FormDialog'; }

	async init () {
		if (!this.viewModel.formModel.form_version_def){
			this.viewModel.formModel.fetchForm().catch((err)=>{
				Grape.alerts.alert({title:'Failed', message:'There was a problem fetching the form.'});
			})
		}
	};
}

export default {
	name: 'FormDialog',
	dialog_class: FormDialogClass,
	template: template,
	provider: 'ps'
};
