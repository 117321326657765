import template from './ko-fieldset.html';

class ComponentVM {
	constructor(params) {
		this.initialContext = ko_helper.safe_observable(params.context);
		this.formComponent = params.formComponent;
		this.legendText = ko.observable(params.formComponent.legendText || '');
		this.formComponents = ko.observableArray(this.formComponent.properties);
		this.context = ko.observable(Object.assign({}, this.initialContext(), {formComponents: this.formComponents()}));
	}

};

window.FormsClient.registerUIComponent({type:'fieldset',ui_component:{ name:'ko-fieldset', schema:{}}});

export default {
	name: 'ko-fieldset',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
