import template from './ko-row.html';
import FormsClient from '../lib/FormsClient';

class ComponentVM {
	constructor(params) {
		this.data = ko_helper.safe_observable(params.data || {});
		this.properties = ko_helper.safe_observable(this.data().properties || {});
		this.row_options = ko_helper.safe_observable(params.row_options || {});
	}

};

window.FormsClient.registerUIComponent({type:'row',ui_component:{ name:'ko-row', schema:{}}});

export default {
	name: 'ko-row',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
