class ExpressionResult {
	constructor(result = false, message = "", errLvl = "error") {
		this.result = result;
		this.message = message;
		this.errLvl = errLvl; // remove? ***********************************************************************
	}
}

class ExpressionRegistry {
	static expressions = {

		'leading_whitespace': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				let ret = { result: true, message: '' };
				let re = /^\s+/;
				let whitespace = re.test(input);

				if (whitespace) {
					ret.result = false;
					ret.message = 'Must not contain any leading whitespace';
					return new ExpressionResult(ret.result, ret.message);
				}
				else {
					return new ExpressionResult(ret.result, ret.message);
				}

			}
		},
		'trailing_whitespace': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				let ret = { result: true, message: '' };
				let re = /\s+$/;
				let whitespace = re.test(input);

				if (whitespace) {
					ret.result = false;
					ret.message = 'Must not contain any trailing whitespace';
					return new ExpressionResult(ret.result, ret.message);
				}
				else {
					return new ExpressionResult(ret.result, ret.message);
				}

			}
		},
		'age_calculation_by_id': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				input = input[0];

				let today = new Date();
				let dd = today.getDate();
				let mm = today.getMonth() + 1;
				let yyyy = today.getFullYear();

				let input_year = input.substring(0, 2);
				let input_month = input.substring(2, 4);
				let input_day = input.substring(4, 6);
				let test = yyyy.toString().substring(2, 4);

				let age = {};

				if ((input_year != "") && (input_month != "") && (input_day != "")) {
					if ((test >= input_year)) {
						input_year = '20' + input_year.toString();
						age['year'] = input_year;
					}
					else {
						input_year = '19' + input_year.toString();
						age['year'] = input_year;
					}

					age['month'] = input_month;
					age['day'] = input_day;

					if (input_month > parseInt(mm)) {
						age['age'] = parseInt(yyyy) - parseInt(input_year) - 1;
					}
					else if ((input_month == parseInt(mm)) && (input_day > parseInt(dd))) {
						age['age'] = parseInt(yyyy) - parseInt(input_year) - 1;

					}
					else {
						age['age'] = parseInt(yyyy) - parseInt(input_year);
					}



				}

				return age;

			}
		},
		'is_too_young': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				let result;
				let message;

				input = input[0];

				if (input.length > 6) {
					let age = ExpressionRegistry.expressions["age_calculation_by_id"].func(null, [input]);

					if (isNaN(input)) {
						result = false;
						message = " Not a number";
					}
					else if (age['age'] < 18) {
						result = false;
						message = "Under 18";
					}
					else if (age['age'] > 79) {
						result = false;
						message = "Over 80";
					}
					else {
						result = true;
						message = "";

					}
				}
				else {
					result = true;
					message = "";

				}

				return new ExpressionResult(result, message);

			}
		},
		'is_age': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				if (input == "") {
					return new ExpressionResult(false, '');
				}

				let age = parseInt(input);
				if (age < 18)
					return new ExpressionResult(false, 'Too young, lead not eligible.');
				if (age > 120)
					return new ExpressionResult(false, 'Too old, lead not eligible.');

				return ExpressionRegistry.expressions["is_integer"].func(null, age);

			}
		},
		'is_dob': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				let re = /^(19|20)\d\d[/](0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])$/;

				input = input[0];

				if (input == "") {
					return new ExpressionResult(false, '');
				}

				if (!input.match(re))
					return new ExpressionResult(false, 'Invalid date format (format YYYY/MM/DD)');

				let date = moment(input, 'YYYY/MM/DD', true);
				if (!date.isValid())
					return new ExpressionResult(false, 'Provided date does not exist (format YYYY/MM/DD)');

				return new ExpressionResult(true, '');

			}
		},
		'is_year': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				let re = /^\d{4}$/;

				input = input[0];

				if (!input.match(re))
					return new ExpressionResult(false, 'Not a valid year');
				return new ExpressionResult(true, '');

			}
		},
		'is_yob': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				input = input[0];

				if (input == "") {

					return new ExpressionResult(false, '');

				}

				if (input != "") {

					let currentDate = new Date;
					let age = currentDate.getFullYear() - parseInt(input);
					return ExpressionRegistry.expressions["is_age"].func(null, age.toString());

				}

			}
		},
		'is_id': {
			description: "",
			params: "",
			example: "",
			func: (context, id_num) => {

				//let idNumber = id_num;
				let warning = "";

				if (!ExpressionRegistry.expressions["is_integer"].func(null, id_num).result) {
					warning = warning.concat("Identity number,");
				}

				id_num = id_num[0];

				if (id_num == "") {
					return new ExpressionResult(false, '');
				}

				let tempDate = new Date(id_num.substring(0, 2), id_num.substring(2, 4) - 1, id_num.substring(4, 6));
				let id_date = tempDate.getDate();
				let id_month = tempDate.getMonth();
				let id_year = tempDate.getFullYear();
				let fullDate = id_date + "-" + (id_month + 1) + "-" + id_year;

				if (!((tempDate.getYear() == id_num.substring(0, 2)) && (id_month == id_num.substring(2, 4) - 1) && (id_date == id_num.substring(4, 6)))) {
					warning = warning.concat("Identity number incorrect date,");
				}

				let genderCode = id_num.substring(6, 10);
				let gender = parseInt(genderCode) < 5000 ? "Female" : "Male";
				let citzenship = parseInt(id_num.substring(10, 11)) == 0 ? "Yes" : "No";

				let tempTotal = 0;
				let tempEven = 0;
				let tempOdd = 0;
				let checkSum = 0;

				for (let i = 1; i < 13; i += 2) {
					tempEven = parseInt(id_num.charAt(i - 1));
					tempOdd = (parseInt(id_num.charAt(i)) * 2);

					if (tempOdd > 9) {
						tempOdd = parseInt(tempOdd) - 9;
					}

					checkSum = checkSum + tempEven + tempOdd;
				}

				checkSum = (10 - (checkSum % 10)) % 10;

				if (checkSum != parseInt(id_num.charAt(12))) {
					warning = warning.concat(" Not a valid ID number");
				}

				let proceed = (warning == "") ? true : false;

				return new ExpressionResult(proceed, warning);

			}
		},
		'is_valid_id_length': {
			description: "",
			params: "",
			example: "",
			func: (context, id_num) => {

				let warning = "";

				id_num = id_num[0];

				if (id_num.length != 13) {
					warning = warning.concat(" ID number is not a valid length");
				}
				let proceed = (warning == "") ? true : false;

				return new ExpressionResult(proceed, warning);

			}
		},
		'is_a_passport': {
			description: "",
			params: "",
			example: "",
			func: (context, id_num) => {

				//let idNumber = id_num;
				let warning = "";

				id_num = id_num[0];

				if (isNaN(id_num)) {
					warning = warning.concat("Most Passport numbers only have numerical values");
				}

				if (id_num.length != 9) {
					warning = warning.concat(" Most Passport numbers are 9 characters");
				}

				let proceed = (warning == "") ? true : false;

				return new ExpressionResult(proceed, warning);

			}
		},
		'is_integer': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				let re = /^\d+$/;
				let message = '';
				let result = re.test(input);
				if (!result)
					message = 'Must only contain digits';

				return new ExpressionResult(result, message);

			}
		},
		'is_float': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				// let n = input.replace(/\s/g, '')
				let re = /^\d+\.?\d*$/;
				let message = '';
				// let result = re.test(n.trim());
				let result = re.test(input);
				if (!result)
					message = 'Must be a float';

				return new ExpressionResult(result, message);

			}
		},
		'is_letters': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				// let s = input.replace(/\s/g, '')
				let re = /^[a-z]+$/i;
				let message = '';
				// let result = re.test(s.trim());
				let result = re.test(input);
				if (!result)
					message = 'Must only contain letters';

				return new ExpressionResult(result, message);

			}
		},
		'prefix_number_check': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				if (/^0\d+/.test(input))
					return new ExpressionResult(true, '');
				else
					return new ExpressionResult(false, 'Contact number must begin with 0');


			}
		},
		'is_phone_number': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				input = input[0];

				if (input.length > 0) {
					let is_len_10 = ExpressionRegistry.expressions["is_length"].func(null, '==', 10);
					let res1 = is_len_10(input);
					let res2 = ExpressionRegistry.expressions["is_integer"].func(null, input);
					let res3 = ExpressionRegistry.expressions["prefix_number_check"].func(null, input);

					if (res1.result & res2.result & res3.result)
						return new ExpressionResult(true, '');
					else if (!res1.result)
						return new ExpressionResult(false, 'Must be 10 numbers long');
					else if (!res2.result)
						return new ExpressionResult(false, 'Must contain only numbers');
					else if (!res3.result)
						return new ExpressionResult(false, 'Must start with number 0 (zero)');
				} else if (input == "") {
					return new ExpressionResult(false, '');
				}
				else {
					return new ExpressionResult(true, '');
				}

			}
		},
		'is_email': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				if (input[0] === '')
					return new ExpressionResult(true, '');

				let re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
				let message = '';
				// let result = re.test(input.trim());
				let result = re.test(input);
				if (!result)
					message = 'Not a valid email';

				return new ExpressionResult(result, message);

			}
		},
		'is_required': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				let re = /.+/i;
				let message = '';
				let result = false;
				if (input)
					// result = re.test(input.trim());
					result = re.test(input);

				if (!result)
					message = 'This field is required';

				return new ExpressionResult(result, message);

			}
		},
		'is_asked': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				let re = /did not ask/i;
				let message = '';
				// let result = !(re.test(input.trim()));
				let result = !(re.test(input));

				if (!result)
					message = 'This question has not been asked.';

				return new ExpressionResult(result, message);

			}
		},
		'is_empty': {
			description: "",
			params: "",
			example: "",
			func: (context, input) => {

				let re = /.+/i;
				let message = '';
				let result = false;
				if (input)
					//result = re.test(input.trim());
					result = re.test(input);

				if (!result)
					message = 'This field is empty';

				return new ExpressionResult(result, message);

			}
		},
		'is_length': {
			description: "",
			params: "",
			example: "",
			func: (context, op, other) => {

				let ops = {
					'>': { f: function (a, b) { return a > b; }, m: 'Must be more than % character' },
					'<': { f: function (a, b) { return a < b; }, m: 'Must be less than % character' },
					'>=': { f: function (a, b) { return a >= b; }, m: 'Must be more than or equal to % character' },
					'<=': { f: function (a, b) { return a <= b; }, m: 'Must be less than or equal to % character' },
					'==': { f: function (a, b) { return a == b; }, m: 'Must be exactly % character' },
					'!=': { f: function (a, b) { return a != b; }, m: 'Must not be % character' }
				};
				return function (input) {
					let message = '';

					let result = ops[op].f(input.length, other);
					if (!result)
						message = ops[op].m.replace('%', other) + ((other > 1) ? 's' : '');

					return new ExpressionResult(result, message);
				}

			}
		},
		'at_least_one_complete': {
			description: "",
			params: "",
			example: "",
			func: (context, [observable, refs, message, conditions]) => {

				for (const condition of conditions) {

					for (const ref of refs) {

						if (ExpressionRegistry.expressions[condition].func(null, [observable.getValue(ref)]).result) {

							for (const refPop of refs) {

								if (refPop != ref) {

									let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refPop.replace(".value", ".messages")]);

									if ((arr[0]) == message) {

										arr.shift();
										EffectRegistry.effects["setObsVal"].func(null, null, [observable, refPop.replace(".value", ".messages"), arr]);

									}

								}

							}

							return;


						} else if (!ExpressionRegistry.expressions[condition].func(null, [observable.getValue(ref)]).result && ExpressionRegistry.expressions[condition].func(null, [observable.getValue(ref)]).message != "") {

							let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref.replace(".value", ".messages")]);

							if ((arr[0]) == message) {

								arr.shift();
								EffectRegistry.effects["setObsVal"].func(null, null, [observable, ref.replace(".value", ".messages"), arr]);

							}

						} else {

							let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref.replace(".value", ".messages")]);

							function removeElement(arr, elem) {

								return arr.filter(
									(item) => item !== elem);

							}

							arr = removeElement(arr, message);

							if ((arr[0]) != message) {

								arr.unshift(message);

								EffectRegistry.effects["setObsVal"].func(null, null, [observable, ref.replace(".value", ".messages"), arr]);

							}

						}
					}

				}

			}
		},
		'duplicate_phone_numbers': {
			description: "",
			params: "",
			example: "",
			func: (context, [observable, refs]) => {

				let refDup = [];

				for (const ref of refs) {

					if (ExpressionRegistry.expressions["is_phone_number"].func(null, [observable.getValue(ref)]).result) {

						for (const refPop of refs) {

							if (refPop != ref) {

								if (ExpressionRegistry.expressions["is_phone_number"].func(null, [observable.getValue(refPop)]).result) {

									let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refPop.replace(".value", ".messages")]);

									let arr2 = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref.replace(".value", ".messages")]);

									if (ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refPop]) == ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref])) {

										if ((arr2[0]) != "Numbers cannot be duplicates") {

											if ((arr[0]) == "Numbers cannot be duplicates") {

												arr.shift();
												EffectRegistry.effects["setObsVal"].func(null, null, [observable, refPop.replace(".value", ".messages"), arr]);
												EffectRegistry.effects["setObsVal"].func(null, null, [observable, refPop.replace(".value", ".valid"), true]);

											}

											arr2.unshift("Numbers cannot be duplicates");

											EffectRegistry.effects["setObsVal"].func(null, null, [observable, ref.replace(".value", ".messages"), arr2]);
											EffectRegistry.effects["setObsVal"].func(null, null, [observable, ref.replace(".value", ".valid"), false]);

										}

									} else {

										if (!refDup.includes(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref]))) {

											let arrValues = [];

											for (const ref of refs) {

												arrValues.push(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref]));

											}

											const itemCounter = (value, index) => {
												return value.filter((x) => x == index).length;
											};

											if (itemCounter(arrValues, ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref])) > 0) {

												refDup.push(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref]));


												if ((arr2[0]) == "Numbers cannot be duplicates") {

													arr2.shift();
													EffectRegistry.effects["setObsVal"].func(null, null, [observable, ref.replace(".value", ".messages"), arr2]);
													EffectRegistry.effects["setObsVal"].func(null, null, [observable, ref.replace(".value", ".valid"), true]);

												}

											}

										}

									}

								} else {

									if (!refDup.includes(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref]))) {

										let arrValues = [];

										for (const ref of refs) {

											arrValues.push(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref]));

										}

										const itemCounter = (value, index) => {
											return value.filter((x) => x == index).length;
										};

										let arr2 = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref.replace(".value", ".messages")]);

										if (itemCounter(arrValues, ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref])) > 0) {

											refDup.push(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, ref]));

											if ((arr2[0]) == "Numbers cannot be duplicates") {

												arr2.shift();
												EffectRegistry.effects["setObsVal"].func(null, null, [observable, ref.replace(".value", ".messages"), arr2]);
												EffectRegistry.effects["setObsVal"].func(null, null, [observable, ref.replace(".value", ".valid"), true]);

											}

										}

									}

								}

							}

						}

					}

				}

			}
		},
		'extract_from_id': {
			description: "",
			params: "",
			example: "",
			func: (context, [observable, refs]) => {

				if ((ExpressionRegistry.expressions["is_id"].func(null, [observable.getValue(refs[0])]).result) || ((ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refs[0]]).length >= 6) && (!isNaN(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refs[0]]))))) {

					let obj = ExpressionRegistry.expressions["age_calculation_by_id"].func(null, [(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refs[0]]))]);

					let dob = obj['year'] + '/' + obj['month'] + '/' + obj['day'];

					EffectRegistry.effects["setObsVal"].func(null, null, [observable, refs[1], dob]);

				}

				if (ExpressionRegistry.expressions["is_dob"].func(null, [observable.getValue(refs[1])]).result) {

					let value = (ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refs[1]])).replace(/\D/g, '');

					value = value.substring(2, 8);

					let obj = ExpressionRegistry.expressions["age_calculation_by_id"].func(null, [value]);

					EffectRegistry.effects["setObsVal"].func(null, null, [observable, refs[2], obj['year']]);

				}

				if (ExpressionRegistry.expressions["is_yob"].func(null, [observable.getValue(refs[2])]).result) {

					let currentDate = new Date;
					let age = String(currentDate.getFullYear() - parseInt(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refs[2]])));

					EffectRegistry.effects["setObsVal"].func(null, null, [observable, refs[3], age]);

				}

				if (ExpressionRegistry.expressions["is_age"].func(null, [observable.getValue(refs[3])]).result) {

					let currentDate = new Date;
					let age = currentDate.getFullYear() - parseInt(ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refs[3]]));

					EffectRegistry.effects["setObsVal"].func(null, null, [observable, refs[2], String(age)]);

				}

			}
		},
		'disable_fields': {
			description: "",
			params: "",
			example: "",
			func: (context, [observable, ref, message, targets]) => {

				for (let target in Object.keys(targets)) {
					for (let key in targets[target]) {

						if (targets[target][key].length > 1) {

							for (let item in targets[target][key]) {

								let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(targets[target][key][item]).replace(".value", ".messages")]);
								let arr2 = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(targets[target][key][item]).replace(".value", ".warningMessages")]);

								if ((arr[0]) == message && arr.length !== 0 && arr2.length === 0) {

									EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key][item]).replace(".value", ".enabled"), true]);

									arr.shift();
									EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key][item]).replace(".value", ".messages"), arr]);

								}

								if ((arr2[0]) == message && arr2.length !== 0 && arr.length === 0) {

									EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key][item]).replace(".value", ".enabled"), true]);

									arr2.shift();
									EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key][item]).replace(".value", ".warningMessages"), arr2]);

								}

							}

						} else {

							let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(targets[target][key]).replace(".value", ".messages")]);
							let arr2 = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(targets[target][key]).replace(".value", ".warningMessages")]);

							if ((arr[0]) == message && arr.length !== 0 && arr2.length === 0) {

								EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key]).replace(".value", ".enabled"), true]);

								arr.shift();
								EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key]).replace(".value", ".messages"), arr]);

							}

							if ((arr2[0]) == message && arr2.length !== 0 && arr.length === 0) {

								EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key]).replace(".value", ".enabled"), true]);

								arr2.shift();
								EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key]).replace(".value", ".warningMessages"), arr2]);

							}

						}
					}
				}

				for (let target in Object.keys(targets)) {
					for (let key in targets[target]) {

						if (targets[target][key].length > 1) {

							for (let item in targets[target][key]) {

								if (ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(ref)]) == key) {

									let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(targets[target][key][item]).replace(".value", ".messages")]);
									let arr2 = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(targets[target][key][item]).replace(".value", ".warningMessages")]);

									if (arr === "" || typeof(arr) ==='string')
										arr = [arr]

									function removeElement(arr, elem) {

										return arr.filter(
											(item) => item !== elem);

									}

									arr = removeElement(arr, message);

									if (arr2 === "" || typeof(arr2) ==='string')
										arr2 = [arr2]

									arr2 = removeElement(arr2, message);

									if ((arr[0]) != message && arr.length !== 0 && arr2.length === 0) {

										EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key][item]).replace(".value", ".enabled"), false]);

										arr.unshift(message);
										EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key][item]).replace(".value", ".messages"), arr]);

									}

									if ((arr2[0]) != message && arr2.length !== 0 && arr.length === 0) {

										EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key][item]).replace(".value", ".enabled"), false]);

										arr2.unshift(message);
										EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key][item]).replace(".value", ".warningMessages"), arr2]);

									}

								}

							}

						} else {

							if (ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(ref)]) == key) {

								let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(targets[target][key]).replace(".value", ".messages")]);
								let arr2 = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, String(targets[target][key]).replace(".value", ".warningMessages")]);

								function removeElement(arr, elem) {

									return arr.filter(
										(item) => item !== elem);

								}

								if (arr === "" || typeof(arr) ==='string')
									arr = [arr]

								if (arr2 === "" || typeof(arr2) ==='string')
									arr2 = [arr2]

								arr = removeElement(arr, message);

								arr2 = removeElement(arr2, message);

								if ((arr[0]) != message && arr.length !== 0 && arr2.length === 0) {

									EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key]).replace(".value", ".enabled"), false]);

									arr.unshift(message);

									if (arr.length > 2)
										arr.splice(1, 1);

									EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key]).replace(".value", ".messages"), arr]);

								}

								if ((arr2[0]) != message && arr2.length !== 0 && arr.length === 0) {

									EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key]).replace(".value", ".enabled"), false]);

									arr2.unshift(message);

									if (arr2.length > 2)
										arr2.splice(1, 1);

									EffectRegistry.effects["setObsVal"].func(null, null, [observable, String(targets[target][key]).replace(".value", ".warningMessages"), arr2]);

								}

							}

						}
					}
				}

			}
		},
		'one_complete_one_required': {
			description: "",
			params: "",
			example: "",
			func: (context, [observable, refComplete, refRequired, message, condition]) => {

				if (ExpressionRegistry.expressions[condition].func(null, [observable.getValue(refComplete)]).result) {

					let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refRequired.replace(".value", ".messages")]);

					function removeElement(arr, elem) {

						return arr.filter(
							(item) => item !== elem);

					}

					arr = removeElement(arr, message);

					if ((arr[0]) != message) {

						arr.unshift(message);

						EffectRegistry.effects["setObsVal"].func(null, null, [observable, refRequired.replace(".value", ".messages"), arr]);

					}

				} else if (!ExpressionRegistry.expressions[condition].func(null, [observable.getValue(refComplete)]).result && ExpressionRegistry.expressions[condition].func(null, [observable.getValue(refComplete)]).message != "") {

					let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refRequired.replace(".value", ".messages")]);

					function removeElement(arr, elem) {

						return arr.filter(
							(item) => item !== elem);

					}

					arr = removeElement(arr, message);
					EffectRegistry.effects["setObsVal"].func(null, null, [observable, refRequired.replace(".value", ".messages"), arr]);

				} else {

					let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refRequired.replace(".value", ".messages")]);

					function removeElement(arr, elem) {

						return arr.filter(
							(item) => item !== elem);

					}

					arr = removeElement(arr, message);
					EffectRegistry.effects["setObsVal"].func(null, null, [observable, refRequired.replace(".value", ".messages"), arr]);

				}

				if (ExpressionRegistry.expressions[condition].func(null, [observable.getValue(refRequired)]).result) {

					let arr = ExpressionRegistry.expressions["getObsVal"].func(null, [observable, refRequired.replace(".value", ".messages")]);

					function removeElement(arr, elem) {

						return arr.filter(
							(item) => item !== elem);

					}

					arr = removeElement(arr, message);
					EffectRegistry.effects["setObsVal"].func(null, null, [observable, refRequired.replace(".value", ".messages"), arr]);

				}

			}
		},
























		"true": {
			description: "Expression that takes no arguments and always returns true",
			params: "NA",
			example: { "expFunc": "true", "expParams": [] },
			func: (context, params) => {
				return true;
			}
		},
		'and': {
			description: "Expression that takes an array of items to and together returning the resulting boolean",
			params: "[boolean1, boolean2...]",
			example: { "expFunc": "and", "expParams": [true, true] },
			func: (context, params) => {
				return params.reduce((accumulator, currentValue) => { return accumulator && currentValue });
			}
		},
		'or': {
			description: "",
			params: "",
			example: "",
			func: (context, params) => {
				return params.reduce((accumulator, currentValue) => { return accumulator || currentValue });
			}
		},
		'>': {
			description: "",
			params: "",
			example: "",
			func: (context, [lo, ro]) => {
				let result = lo > ro;
				let message = '';

				if (!result)
					message = `${lo} is not greater than ${ro}`;

				return new ExpressionResult(result, message);
			}
		},
		'<': {
			description: "",
			params: "",
			example: "",
			func: (context, [lo, ro]) => {
				let result = lo < ro;
				let message = '';

				if (!result)
					message = `${lo} is not less than ${ro}`;

				return new ExpressionResult(result, message);
			}
		},
		'=': {
			description: "Expression to do an equality check between two operands",
			params: "[left hand operand, right hand operand]",
			example: { "expFunc": "=", "expParams": [1, 1] },
			func: (context, [lo, ro, strict, invertResult, customMessage]) => {
				let result;
				if (strict) result = lo === ro;
				else result = lo == ro;
				let message = '';

				if (!result && invertResult == undefined)
					message = `${lo} is not equal to ${ro}`;

				if(result && invertResult && customMessage)
					return new ExpressionResult(!result, customMessage);
				else if(!result && invertResult && customMessage)
					return new ExpressionResult(!result, "");

				return new ExpressionResult(result, message);
			}
		},
		'<=': {
			description: "",
			params: "",
			example: "",
			func: (context, [lo, ro, strict]) => {
				let result;
				if (strict) result = lo === ro || lo < ro;
				else result = lo <= ro;
				let message = '';

				if (!result)
					message = `${lo} is not less than or equal to ${ro}`;

				return new ExpressionResult(result, message);
			}
		},
		'uppercase': {
			description: "",
			params: "",
			example: "",
			func: (context, text) => {
				return text.toUpperCase();
			}
		},
		'getCtxAttr': {
			description: "",
			params: "",
			example: "",
			func: (context, attr) => {
				return attr.split('.').reduce((accumulator, currentValue) => { return accumulator[currentValue] }, context);
			}
		},
		'reTest': {
			description: "Expression that does a regular expression test on a specified string",
			params: "[string, pattern, flags]",
			example: { "expFunc": "reTest", "expParams": ["test", "\\w", "g"] },
			func: (context, [string, pattern, flags = '']) => {
				let regexp = new RegExp(pattern, flags);
				let testResult = regexp.test(string);
				let message = '';
				if (!testResult)
					message = 'Pattern not found'
				return new ExpressionResult(testResult, message);
			}
		},
		"count": {
			description: "",
			params: "",
			example: "",
			func: (context, [value, ...array]) => {
				let count = 0;
				for (let item of array) {
					if (item == value) count++;
				}
				return count;
			}
		}

	};
	constructor() {
	}
	static get(name) {
		if (!ExpressionRegistry.expressions.hasOwnProperty(name))
			throw new Error(`No such expression function "${name}"`);

		return ExpressionRegistry.expressions[name];
	}
	static set(name, description, params, example, func, overwrite) {
		if (!overwrite && ExpressionRegistry.expressions.hasOwnProperty(name))
			throw new Error(`Expression "${name}" already exists, add the overwrite flag if you want to overwrite it.`)

		ExpressionRegistry.expressions[name] = { description, params, example, func };
	}
}

class EffectRegistry {
	static effects = {
		log: {
			description: "",
			params: "",
			example: {},
			func: (context, result, [text]) => {
				console.log(text);
			}
		},
		setCtxAttr: {
			description: "",
			params: "",
			example: {},
			func: (context, result, [attr, value]) => {
				context[attr] = value;
			}
		}
	};
	constructor() {
	}
	static get(name) {
		if (!EffectRegistry.effects.hasOwnProperty(name))
			throw new Error(`No such effect function "${name}"`);

		return EffectRegistry.effects[name];
	}
	static set(name, description, params, example, func, overwrite) {
		if (!overwrite && EffectRegistry.effects.hasOwnProperty(name))
			throw new Error(`Effect "${name}" already exists, add the overwrite flag if you want to overwrite it.`)

		EffectRegistry.effects[name] = { description, params, example, func };
	}
}

let expressionRegistry = ExpressionRegistry; // TODO update variables
let effectRegistry = EffectRegistry; // TODO update variables

let buildExpression = (definition) => {
	//define message in function scope and run only once
	let messages = [];
	let count = 1;
	let buildExpression2 = (definition) => {
		let expFunc = expressionRegistry.get(definition.expFunc).func;
		let params = (context) => {
			// parse the parameters
			let params = [];

			// [].concat used in case params is not an array
			for (let param of [].concat(definition.expParams)) {
				// if the param is not an object or is an array then return the raw value
				if (param == undefined || typeof param != 'object' || Array.isArray(param)) {
					params.push(param);
					continue;
				}

				// if the expFunc property exists then parse it as a expression
				if (param.hasOwnProperty('expFunc')) {
					count++;
					let expressionResult = (buildExpression2(param))(context);
					if (expressionResult instanceof ExpressionResult) {
						params.push(expressionResult.result);
					}
					else {
						params.push(expressionResult);
					}
				}
			}
			// return array if params was an array
			if (Array.isArray(definition.expParams))
				return params;

			// return single value if params was not an array
			return params[0];
		}

		// return a closure so that we can inject the context object etc
		// needed to add a reset arg in case you want to run the function multiple times to reset the messages
		return (context, reset) => {
			// if (reset){
			// 	messages = [];
			// 	done = false;
			// }

			let result = expFunc(context, params(context));

			//run all effects for each expFunc
			if (definition.hasOwnProperty('effects')) {
				for (let effect of [].concat(definition.effects)) {
					let effFunc = effectRegistry.get(effect.effFunc).func;

					let params = (context) => {
						// parse the parameters
						let params = [];

						// [].concat used in case params is not an array
						for (let param of [].concat(effect.effParams)) {
							// if the param is not an object or is an array then return the raw value
							if (param == undefined || typeof param != 'object' || Array.isArray(param)) {
								params.push(param);
								continue;
							}

							// if the expFunc property exists then parse it as a expression
							if (param.hasOwnProperty('expFunc')) {
								count++;
								let expressionResult = (buildExpression2(param))(context);
								if (expressionResult instanceof ExpressionResult) {
									params.push(expressionResult.result);
								}
								else {
									params.push(expressionResult);
								}
							}
						}
						// return array if params was an array
						if (Array.isArray(effect.effParams))
							return params;

						// return single value if params was not an array
						return params[0];
					}

					// TODO should we only return result in case of a ExpressionResult for example?
					if (effect.ifResult === undefined) {
						effFunc(context, result, params(context));
					} else if (effect.ifResult == ((result instanceof ExpressionResult) ? result.result : result)) {
						effFunc(context, result, params(context));
					}
				}
			}

			count--;
			if (result instanceof ExpressionResult) {
				if (result.message)
					messages.push(result.message);
				return result;
			}

			let finalMessages;
			// do some things once recursion is done
			if (count === 0) {
				finalMessages = messages;

				// reset for future runs
				messages = [];
				count = 1;

			}

			return new ExpressionResult(result, finalMessages);

			// if(finalMessages == undefined || finalMessages.length == 0){

			// 	return new ExpressionResult(result, finalMessages);

			// }else{

			// 	return new ExpressionResult(result, finalMessages[0]);

			// }


		}
	}
	return buildExpression2(definition);
}

module.exports = { buildExpression, ExpressionResult, ExpressionRegistry, EffectRegistry };
