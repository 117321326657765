import { ExpressionRegistry, EffectRegistry } from "./forms/Expression";

if (!Grape.registry.hasRegister('ui-ps-forms-client'))
	Grape.registry.addRegister('ui-ps-forms-client');

if (!Grape.registry.hasItem('ui-ps-forms-client', 'components'))
	Grape.registry.addItem('ui-ps-forms-client', 'components', {});

class FormsClient
{

	static ui_registry = Grape.registry.getRegister('ui-ps-forms-client');

	static ExpressionRegistry = ExpressionRegistry;
	static EffectRegistry = EffectRegistry;

	static registerUIComponent({type, ui_component, override}){
		let ui_components = FormsClient.ui_registry.get('components');

		if (ui_components[type] && !override)
			throw new Error(`Type "${type} already exists you can override it with {override:true}"`);

		ui_components[type] = ui_component;
	};

	static getUIComponent(type){
		let ui_components = FormsClient.ui_registry.get('components');
		return ui_components[type] || {name: 'ko-unknown'};
	}

	static psFormKoObservable({observable, ref='', owner}={}){
		if (!ko)
			throw new Error('Knockoutjs library not found');

		let data_model_value = observable;//observable.getObservableRef(ref);
		let valueUpdated = ko.observable();
		let value = ko.computed({
			read:()=>{
				valueUpdated();
				return data_model_value();//get
			},
			write:(newValue)=>{
				return data_model_value(newValue);//set
			},
			owner:owner
		});
		data_model_value.subscribe(()=>{// when value in data object changes update viewmodel value
			valueUpdated.valueHasMutated();
		});

		return value;
	}

	constructor()
	{
	}

	static instanceDataModel(formDataModel) {

	}
}

window.FormsClient= FormsClient;

export default FormsClient;
