const resolveValueRef = ({valueRef, observable, resultType='value'})=>{
	// console.log('resolveValueRef observable:',valueRef,observable());
	if (typeof valueRef !== 'string' || !valueRef.startsWith('$root.'))
		return valueRef;

	const path = valueRef.slice(6);
	// console.log('path', path);
	const value = path.split('.').reduce((o, i) => {
		// console.log('reduce:',o(), i, typeof i, o[i], Object.entries(o));
		return o._value[i];
	}, observable);
	if (resultType === 'value')
		return value();

	return value;
}

class Observable extends Function {

	constructor(initialValue){
		super('return arguments.callee._call.apply(arguments.callee, arguments)');
		this.subscriptions = {};
		this.child_subscriptions = {};
		this._value = initialValue;
	}

	_call(ref='$', newValue=undefined, resolve=true){ // TODO maybe should be able to specify the ref string char
		if (newValue != undefined){
			// console.log('setting value');
			return this.setValue(ref, newValue);
		}

		return this.getValue(ref, resolve);
	}

	getValue(ref='$', resolve=true){
		if (typeof ref !== 'string' || !ref.startsWith('$'))
			throw new Error(`'${JSON.stringify(ref)}' is not a reference string`);

		let path = ref?.replace('$', '');// clear $ that indicates string is a ref
		// console.log('getValue: ',this._value);

		let value;
		if (resolve) {
			value = this.resolveRef(getValueFromPath({path, obj:this._value}));
		} else {
			value = getValueFromPath({path, obj:this._value});
		}
		// TODO handle nested ref strings
		value ??= null; //cause undefined is not valid JSON

		// if the value is object resolve any contained ref strings
		// TODO use this method of resolving nested ref strings for all instead of above
		if (typeof value === 'object' && resolve){
			let valueJSON = JSON.stringify(value);

			valueJSON = valueJSON.replaceAll(/(?<!"scope":\s*)"\$[^"]*"/g, (match)=>{
				return JSON.stringify(this.getValue(match.replaceAll('"', '')));
			})

			value = JSON.parse(valueJSON);
		}
		// return JSON.parse(JSON.stringify(value));//return by value
		return value;
	}

	// TODO look at setting a value that is actually a path ref string?
	setValue(ref, newValue){
		if (typeof ref !== 'string' || !ref.startsWith('$'))
			throw new Error(`"${ref}" is not a reference string`);

		let path = ref?.replace('$', '');
		if (path == null){
			throw new Error('replacing entire observable not yet supported');
		} else {
			if (getValueFromPath({path, obj:this._value})!==newValue) {// need special behavior for equality checks on objects and arrays
				setValueAtPath({path, obj:this._value, newValue});
				this.runSubscriptions(ref);
			}
		}

		return newValue;
	}

	push(ref, newValue){
		let path = ref?.replace('$', '');

		let array = this.resolveRef(getValueFromPath({path, obj:this._value}));

		if (!Array.isArray(array))
			throw new Error('Can only use push on an array');

		array.push(newValue);
		this.runSubscriptions(ref);
	}

	splice(ref, start, steps){
		// TODO update subscriptions?
		let path = ref?.replace('$', '');

		let array = this.resolveRef(getValueFromPath({path, obj:this._value}));

		if (!Array.isArray(array))
			throw new Error('Can only use splice on an array');

		let result = array.splice(start, steps);
		this.runSubscriptions(ref);
		return result;
	}

	pop(ref){
		let path = ref?.replace('$', '');

		let array = this.resolveRef(getValueFromPath({path, obj:this._value}));

		if (!Array.isArray(array))
		throw new Error('Can only use pop on an array');

		let result = array.pop();
		this.runSubscriptions(ref);
		return result;
	}

	runSubscriptions(ref='$', all=true){
		// TODO maybe make triggering on child changes optional?
		let refs = Object.keys(this.subscriptions);// get all paths that have subscriptions
		let matchedRefs = refs.filter((val)=>{
			if (all)
				return ref.startsWith(val);
			else
				return ref === val;
		}); // keep only paths that start with path being modified
		matchedRefs.sort().reverse();// always run bottom level path subscriptions first

		for (let p of matchedRefs){
			if (this.subscriptions[p]){
				for (let sub of this.subscriptions[p]){ // loop through all callbacks for each matched path
					sub(this.getValue(ref), {newObj:this.getValue(), ref:ref, subscriptionRef:p}); // run the subscription returning the newValue the path for the changed value and the path that the subscription was on
				}
			}
		}
	}

	subscribe(ref, callback, resolve=true) {
		// TODO subscribe to all nested ref strings, add a flag?
		if (!this.subscriptions[ref])
			this.subscriptions[ref] = [];

		this.subscriptions[ref].push(callback);

		//subscribe to nested ref strings
		if (!resolve)
			return;

		for (let match of [...new Set([...JSON.stringify(this.getValue(ref, false)).matchAll(/"([^"]*)":\s*"(\$[^"]*)"/g)])]){
			if (match[1] === 'scope') // TODO allow list of fields to skip
				continue;
			this.subscribe(match[2], callback);// TODO make relationships clearer
		}
	}

	unsubscribe(ref, callback) {
		this.subscriptions[ref].splice(this.subscriptions[ref].indexOf(callback));
	}

	getObservableRef(ref){
		return observableRef(this, ref);
	}

	resolveRef(ref){
		if (typeof ref === 'string' && ref.startsWith('$'))
			return this.getValue(ref);

		return ref;
	}

	resolveRefsInObject(obj){
		if (typeof obj === 'object'){
			let objJSON = JSON.stringify(obj);
			let matches = objJSON.match(/"\$[^"]*"/g);
			if (matches !== null){
				for (let match of matches){
					objJSON = objJSON.replace(match, JSON.stringify(this.getValue(match.replaceAll('"', ''))));
				}
			}
			obj = JSON.parse(objJSON);
		}

		return obj;
	}
}

const observable = (value, scope)=>{
	return new Observable(value, scope);
}

const getValueFromPath = ({path, obj})=>{
	if (path=='')
		return obj;

	const keys = path.split('.');
	let value = obj;

	for (const key of keys) {
		if (key.includes('[') && key.includes(']')) {
			const arrayKey = key.substring(0, key.indexOf('['));
			const index = parseInt(key.substring(key.indexOf('[') + 1, key.indexOf(']')));
			value = (isNaN(index)) ? value[arrayKey] : value[arrayKey][index];
		} else {
			value = value[key];
		}

		if (value === undefined) {
			break;
		}
	}

	return value;
}

const setValueAtPath = ({path, obj, newValue})=>{
	const keys = path.split('.');
	let currentObj = obj;

	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		const isArrayIndex = key.includes('[') && key.includes(']');

		if (isArrayIndex) {
			const arrayKey = key.substring(0, key.indexOf('['));
			const index = parseInt(key.substring(key.indexOf('[') + 1, key.indexOf(']')));

			if (!currentObj[arrayKey]) {
				currentObj[arrayKey] = [];
			}

			if (i === keys.length - 1) {
				currentObj[arrayKey][index] = newValue;
			} else {
				currentObj = currentObj[arrayKey][index];
			}
		} else {
			if (i === keys.length - 1) {
				currentObj[key] = newValue;
			} else {
				if (!currentObj[key]) {
					currentObj[key] = {};
				}
				currentObj = currentObj[key];
			}
		}
	}
}

function escapeRegExp(text) {
	return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

class ObservableRef extends Function {
	static observableRefs = {};
	constructor(observable, ref){
		super('return arguments.callee._call.apply(arguments.callee, arguments)');
		this.observable = observable;
		this.ref = ref;
		ObservableRef.add(this);
	};

	static add(observableRef){
		ObservableRef.observableRefs[observableRef.ref] = observableRef;
	}

	static remove(ref){
		delete ObservableRef.observableRefs[ref];
	}

	_call(newValue){//{path, newValue}
		if (newValue != undefined){
			// console.log('setting value');
			return this.setValue(newValue);
		}

		return this.getValue();
	};

	getValue(ref=this.ref, resolve=true){
		if (ref.charAt(0) === '$')
			return this.observable.getValue(ref, resolve);

		if (ref.startsWith("["))
			return this.observable.getValue(`${this.ref}${ref}`, resolve);

		return this.observable.getValue(`${this.ref}.${ref}`, resolve);
	};

	setValue(args){
		if (args.hasOwnProperty('ref')){
			if (args.ref.charAt(0) === '$')
				return this.observable.setValue(args.ref, args);

			if (args.ref.startsWith("["))
				return this.observable.setValue(`${this.ref}${args.ref}`, args.newValue);

			return this.observable.setValue(`${this.ref}.${args.ref}`, args.newValue);

		}

		return this.observable.setValue(this.ref, args);
	};

	push(newValue){
		this.observable.push(this.ref, newValue);
	};

	splice(start, steps){
		this.observable.splice(this.ref, start, steps);
		// run through observable refs and update indexes
		for (let ref of Object.keys(ObservableRef.observableRefs)){
			if (ref.includes(this.ref+'[')){
				// regex to get index by escape regex chars
				let regexp = `(\\${this.ref.replaceAll('.', '\\.').replaceAll('[', '\\[').replaceAll(']', '\\]')}\\[)(\\d+)(\\].*)`;
				let matches = ref.match(regexp);
				let index = parseInt(matches[2]);

				// remove observable refs that were removed by splice
				if (index >= start && index < (start+steps)){
					ObservableRef.remove(ref);
				}

				// dont do anything with indexes unaffected by splice
				if (index<(start+steps)){
					continue;
				}

				// update index of indexes affected by splice and update observableRefs
				let newRef = matches[1]+(index-steps)+matches[3];
				ObservableRef.observableRefs[ref].ref = newRef;
				ObservableRef.add(ObservableRef.observableRefs[ref]);
				ObservableRef.remove(ref);
			}
		}
	};

	pop(){

	};

	runSubscriptions(ref, all=true){
		this.observable.runSubscriptions(ref || this.ref, all);
	}

	subscribe(ref=this.ref, callback){

		if (typeof ref === 'function'){
			callback = ref;
			ref = this.ref;
		}
		else if (ref.charAt(0) !== "$"){
			ref = `${this.ref}.${ref}`;
		}

		this.observable.subscribe(ref, callback);
	};

	unsubscribe(callback, path){
		if (!path)
			path = this.path;

		this.observable.unsubscribe({path, callback});
	};

	getObservableRef(ref){
		if (ref.charAt(0) !== '$')
			ref = `${this.ref}.${ref}`;

		return observableRef(this.observable, ref);
	}
}

const observableRef = (observable, ref)=>{
	return new ObservableRef(observable, ref);
}

module.exports = {observableRef, observable, resolveValueRef, getValueFromPath, setValueAtPath, Observable};
// export {
// 	observable
// };
