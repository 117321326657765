import template from './ko-group.html';
import FormsClient from '../lib/FormsClient';

class GroupVM {
	constructor(params) {
		this.initialContext = ko_helper.safe_observable(params.context);
		this.formComponent = params.formComponent;
		this.formComponents = ko.observableArray(this.formComponent.properties);
		this.context = ko.observable(Object.assign({}, this.initialContext(), {formComponents: this.formComponents()}));
	}

};

window.FormsClient.registerUIComponent({type:'group',ui_component:{ name:'ko-group', schema:{
	"$schema": "forms/input",
	"$id": "forms-input",
	"title": "Input Component",
	"description": "An input component for forms",
	"type": "object",
	"required": ["type", "properties"],
	"properties": {
		"type": {
			"type": "string",
			"description": "the component type"
		},
		"properties": {
			"type": "object",
			"description": "The component properties",
			"required": ["scope"],
			"properties": {
				"scope": {
					"type": "string",
					"description": "A reference string with a path to the variable storing the value"
				},
				"visible": {
					"type": "boolean",
					"description": "Control component visibility",
					"default": true
				},
				"disabled": {
					"type": "boolean",
					"description": "Disable component",
					"default": false
				},
				"label": {
					"type": "string",
					"description": "The component label",
					"default": ""
				},
				"showLabel": {
					"type": "boolean",
					"description": "Show component label",
					"default": false
				},
				"type": {
					"type": "string",
					"description": "Supported html input element type",
					"default": "text"
				},
				"gridColumn": {
					"type": "string",
					"description": "Supported css gridColumn value for positioning inside a gridDisplay",
					"default": ""
				},
				"gridRow": {
					"type": "string",
					"description": "Supported css gridRow value for positioning inside a gridDisplay",
					"default": ""
				}

			}
		}
	}
}}}); // register the name used in the ui model to map to the name of the actual ko component

export default {
	name: 'ko-group',
	viewModel: GroupVM,
	module_type: 'ko',
	template: template
};
