import template from './ko-textarea.html';
import {resolveValueRef, observablePath} from '../lib/Observables.js';

class ComponentVM {
	constructor(params) {
		this.context = ko_helper.safe_observable(params.context);
		this.formComponent = params.formComponent;
		this.label = ko.observable(this.formComponent.label || '');
		this.fieldInstance = this.context().form.getFieldInstance(this.formComponent.field);// get the fuel
		this.value = FormsClient.psFormKoObservable({observable: this.fieldInstance.value, ref:null, owner:this});
		this.valid = FormsClient.psFormKoObservable({observable: this.fieldInstance.valid, ref:null, owner:this});
		this.messages = FormsClient.psFormKoObservable({observable: this.fieldInstance.messages, ref:null, owner:this});
		this.warningMessages = FormsClient.psFormKoObservable({observable: this.fieldInstance.warningMessages, ref:null, owner:this});
		this.enabled = FormsClient.psFormKoObservable({observable: this.fieldInstance.enabled, ref:null, owner:this});
		this.visible = FormsClient.psFormKoObservable({observable: this.fieldInstance.visible, ref:null, owner:this});
		this.fieldMessages = ko.computed(()=>{
			let messages = this.messages();
			if (messages && messages.length && messages.length > 0)
				return JSON.stringify(messages);
			return '';
		})

		this.fieldWarningMessages = ko.computed(()=>{ //input
			let warningMessages = this.warningMessages();
			if (warningMessages && warningMessages.length && warningMessages.length > 0)
				return JSON.stringify(warningMessages);
			return '';
		})

	}

};

window.FormsClient.registerUIComponent({type:'textarea',ui_component:{ name:'ko-textarea', schema:{}}});

export default {
	name: 'ko-textarea',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
