import template from './ko-unknown.html';

class ComponentVM {
	constructor(params) {
		this.context = ko_helper.safe_observable(params.context);
		this.formComponent = params.formComponent;
		this.label = ko.observable(this.formComponent.label || '');
		this.fieldInstance = this.context().form.getFieldInstance(this.formComponent.field);// get the fuel
		this.value = FormsClient.psFormKoObservable({observable: this.fieldInstance.value, ref:null, owner:this});
		this.valid = FormsClient.psFormKoObservable({observable: this.fieldInstance.valid, ref:null, owner:this});
		this.messages = FormsClient.psFormKoObservable({observable: this.fieldInstance.messages, ref:null, owner:this});
		this.enabled = FormsClient.psFormKoObservable({observable: this.fieldInstance.enabled, ref:null, owner:this});
		this.visible = FormsClient.psFormKoObservable({observable: this.fieldInstance.visible, ref:null, owner:this});
		this.fieldMessages = ko.computed(()=>{
			let messages = this.messages();
			if (messages && messages.length && messages.length > 0)
				return JSON.stringify(messages);
			return '';
		})
		this.error_text = ko.observable(`Unknown form ui model type "${this.formComponent.type}"`);
	}

};

window.FormsClient.registerUIComponent({type:'unknown',ui_component:{ name:'ko-unknown', schema:{}}});

export default {
	name: 'ko-unknown',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
