// construct a form object that with methods to manipulate it and optionally render it
const Observables = require('../Observables.js');
const Rule = require('./Rule.js');
const {createField, createObjectField, createListField} = require('./Fields.js');
const Expression = require('./Expression.js');


Expression.ExpressionRegistry.set('getObsVal', "", "", {}, (context, [observable, ref])=>{
	return observable.getValue(ref);
});

Expression.EffectRegistry.set('setObsVal', "Effect that sets an observables value", "[observable, reference string, value]", {"ifResult": true, "effFunc": "setObsVal", "effParams": [{"expFunc": "getCtxAttr", "expParams":"forms.observable"}, "$fields.input.enabled", false]}, (context, result, [observable, ref, newValue])=>{
	observable.setValue(ref, newValue);
});

Expression.EffectRegistry.set('pushObsVal', "", "", {}, (context, result, [observable, ref, newValue])=>{
	let value = observable(ref);
	if (value === undefined || value === "")
		observable.setValue(ref, []);
	if (!Array.isArray(observable(ref)))
		observable.setValue(ref, [value]);

	observable.push(ref, newValue);
});

class Form {
	constructor(definition, data){
		this.defaultDefinition = {
			"rules": []
		}

		this.definition = Object.assign(this.defaultDefinition, definition);
		// if definition has no data need to generate default data
		// if definition has no fields but has data need to generate generic fields
		// if definition has no layout but has data or fields need to generate generic layout
		// rules and validators can be null.
		this.observable = Observables.observable(Object.assign({data:{}}, this.definition||{}));
		this.fields = this.observable.getObservableRef("$fields");
		this.validators = this.observable.getObservableRef("$validators");
		this.rules = this.observable.getObservableRef("$rules");
		this.layout = this.observable.getObservableRef("$layout");
		this.state = 'init';// init/ready?
		this.valid = this.observable.getObservableRef("$valid");
		this.messages = this.observable.getObservableRef("$messages");
		this.rulesTimeout;

		this.context;
		this.init();

		this.fieldId = 0;
		this.fieldInstances = {};
		this.initFieldInstances();

		this.formRules = [];
		this.initFormRules();

		if (data)
			this.setData(data);

	}

	init(){
		this.context = {form: this};
	}

	initFormRules(){
		for (let ruleDefinition of this.observable.getValue('$rules', false)){
			// build list of dependencies and store them somewhere for reference and associated with relevant rule
			// subscribe to list of dependencies for relevant rule to make it run the rule only when needed
			this.formRules.push(Rule(ruleDefinition));
		}

		// TODO this has to trigger on changes to referenced values, might be looking at something other than value
		for (let fieldId of Object.keys(this.fieldInstances)){
			this.fieldInstances[fieldId].value.subscribe(()=>{this.runRules()});
		}

		// this.observable.subscribe('$data', ()=>{this.runRules()});// TODO rules should automatically subscribe to relevant references
	}

	runRules(){

		clearTimeout(this.rulesTimeout);

		this.rulesTimeout = setTimeout(() => {

			// console.log('running rules');
			for (let fieldId of Object.keys(this.fieldInstances)) {
				this.fieldInstances[fieldId].validate();
			}
			for (let rule of this.formRules) {
				rule(this.context);
			}

		}, 10);
		
	}

	genFieldId(){
		return ++this.fieldId;
	}

	getFieldInstance(ref){ // TODO figure out how indexes will work
		let tokens = ref.split('.');
		return tokens.reduce((accumulator, currentValue)=>{
			if (currentValue.endsWith(']')){
				let key = currentValue.match(/([^[]*)/)[0];
				let indexes = currentValue.matchAll(/\[(\d+)\]/g)
				return indexes.reduce((accumulator2, currentValue2)=>{
					return accumulator2.fieldInstances[parseInt(currentValue2[1])]
				}, accumulator.fieldInstances[key]);
			}

			return accumulator.fieldInstances[currentValue];
		}, this);
	}

	initFieldInstances(){
		let fields = this.observable('$fields');
		for (let [i, field] of Object.entries(fields) ){
			let fieldObs = this.observable.getObservableRef(`$fields[${i}]`);
			this.addFieldInstance(fieldObs, field.name);
		}
	}

	addFieldInstance(fieldObs, fieldName){
		// console.log('addFieldInstance: ', fieldObs());
		let field = fieldObs();
		fieldObs.subscribe('value', (newValue)=>{
			// console.log(`$data.${fieldObs().targetPath}`);
			this.observable(`$data.${fieldObs().targetPath}`, newValue);
		});

		let fieldId = this.genFieldId();
		fieldObs.setValue({ref:'fieldId', newValue:fieldId});

		if (field.type === 'object'){
			let newObjectField = createObjectField(fieldObs, this.context);
			this.fieldInstances[fieldName] = newObjectField;
		} else if (field.type === 'list') {
			let newArrayField = createListField(fieldObs, this.context);
			this.fieldInstances[fieldName] = newArrayField;
		} else {
			let newField = createField(fieldObs, this.context);
			this.fieldInstances[fieldName] = newField;
		}
	}

	generateRefStrings(input, parentPath = '') {
		let paths = [];

		// Determine the input type (object or array) and iterate accordingly
		if (typeof input === 'object' && !Array.isArray(input)) {
			// Object: Iterate through each key-value pair
			for (const key in input) {
				const newPath = parentPath ? `${parentPath}.${key}` : key;
				paths = paths.concat(this.generateRefStrings(input[key], newPath));
			}
		} else if (Array.isArray(input)) {
			// Array: Iterate through each element
			input.forEach((item, index) => {
				const newPath = `${parentPath}[${index}]`;
				paths = paths.concat(this.generateRefStrings(item, newPath));
			});
		} else {
			// Leaf node: Add the current path to the paths array
			paths.push(parentPath);
		}

		return paths;
	}

	setData(data){

		for (let fieldName of Object.keys(data)){
			if (this.fieldInstances[fieldName])
				this.fieldInstances[fieldName].setValue(data[fieldName]);
		}

		return this.getData();
	}

	getData(){
		let data = {};
		for (let fieldName of Object.keys(this.fieldInstances))
			data[fieldName] = this.fieldInstances[fieldName].value();

		return data;
	}


	async render(){
		//support different renderers ko, vanilla, react(just as an example)???
	}
}


module.exports = Form;
