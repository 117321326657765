import FormsClient from '../lib/FormsClient';
import template from './ko-component-tree.html';

class ComponentVM {
	constructor(params) {
		// console.log('component tree params',params);
		this.formData = params.formData;
		// console.log('component tree this.formData',this.formData);
		this.treeData = ko_helper.safe_observable(params.treeData);
		this.showDebug = ko_helper.safe_observable(params.showDebug);
		this.index = ko_helper.safe_observable(params.index);
		// console.log(`treedData: ${JSON.stringify(this.treeData())}`);
		this.uiScope=params.uiScope
	}


};

export default {
	name: 'ko-component-tree',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
