import FormsClient from '../lib/FormsClient';
import template from './ko-form.html';
import Form from '../lib/forms/Form';

class ComponentVM {
	constructor(params) {
		this.form_error = ko.observable();

		this.def = ko_helper.safe_observable(params.def);// use this to initialize form
		this.data = ko_helper.safe_observable(params.data);// will be updated with form data
		this.form = ko_helper.safe_observable(params.form);// store initialized form here

		this.formComponents = ko.observableArray();
		this.context = ko.observable();

		this.buildForm();

		this.def.subscribe((newValue)=>{
			this.buildForm();
		});
	}

	generateLayout(fieldPath, fieldDef){
		if (Array.isArray(fieldDef)){
			let fields = [];
			for (let [i, objField] of Object.entries(fieldDef)){
				fields.push(this.generateLayout(fieldPath.concat([fieldDef[i].name]), objField));
			}
			return fields;
		} else if (fieldDef.type === "object" ){
			let properties = []
			for (let [i, objField] of Object.entries(fieldDef.properties)){
				let newFieldPath = Object.assign([], fieldPath);
				newFieldPath.push(fieldDef.properties[i].name);
				properties.push(this.generateLayout(newFieldPath, objField));
			}
			let group = {
				"type": "group",
				"field": fieldPath.join('.'),
				"properties": properties
			}
			return group;
		} else if (fieldDef.type === "list") {
			let newFieldPath = Object.assign([], fieldPath);
			let text = fieldDef.name;//fieldPath[fieldPath.length-1].replaceAll(/(^.|(?<=[_]).)/g, x=>x.toUpperCase()).replaceAll('_', ' ');
			let list = {
				"type": "list",
				"title": text,
				"field": newFieldPath.join('.'),
				"items": [this.generateLayout((newFieldPath.join('.')+'[]').split('.'), fieldDef.items)]
			};
			return list;
		} else {
			// let text = fieldDef.name;//fieldPath[fieldPath.length-1].replaceAll(/(^.|(?<=[_]).)/g, x=>x.toUpperCase()).replaceAll('_', ' ');
			let text = fieldDef.name.replaceAll(/(^.|(?<=[_]).)/g, x=>x.toUpperCase()).replaceAll('_', ' ');

			let type = "input";

			if (fieldDef.type === "select")
				type = "select";

			if (fieldDef.type === "bool"){
				type = "checkbox";
			}

			if (fieldDef.type === "textarea"){
				type = "textarea";
			}

			let field = {
				"type":type,
				"field": fieldPath.join('.'),
				"label": text
			}

			if (fieldDef.type === "select")
				field.options = fieldDef.options || [];


			return field;
		}
	}

	buildForm(){
		try {
			// make sure the initial def object doesn't get modified by form
			let def = JSON.parse(JSON.stringify(this.def()));
			this.form(new Form(def, this.data()||{}));
			// generate layout if needed
			if (!this.form().layout()){
				this.form().layout(this.generateLayout([], this.def().fields));
			}
			this.formComponents(this.form().layout());//create binding between layout and observable
			this.context({form: this.form(), formComponents:this.formComponents(), index:[]});
			this.form_error(``);
		} catch(err) {
			this.form_error(`form error: ${err}`);
			console.log(err);
		}
	}

	toggleShowDebug(){
		this.showDebug(!this.showDebug());
	}

};

export default {
	name: 'ko-form',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
