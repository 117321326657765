import FormsClient from '../lib/FormsClient';
import template from './ko-form-component.html';

class ComponentVM {
	constructor(params) {
		console.log(params);
		this.context = ko_helper.safe_observable(params.context);
		if (this.context() && this.context().hasOwnProperty('index') && this.context().hasOwnProperty('formComponents')){
			for (let formComponent of this.context().formComponents){
				if (formComponent.hasOwnProperty('field')){
					console.log(this.context().index);
					for (let i of this.context().index){
						formComponent.field = formComponent.field.replace('[]', `[${i}]`);
					}
				}
			}
		}
		console.log(this.context().formComponents, this.context().index);
	}


};

export default {
	name: 'ko-form-component',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
