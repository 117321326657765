import template from './ko-list.html';
import {resolveValueRef, observableRef} from '../lib/Observables.js';

class ComponentVM {
	self;
	constructor(params) {
		this.initialContext = ko_helper.safe_observable(params.context);
		this.formComponent = params.formComponent;
		this.title = ko.observable(params.formComponent.title || 'List');
		this.legendText = ko.observable(params.formComponent.legendText || '');
		this.formComponents = ko.observableArray(this.formComponent.properties);
		this.context = ko.observable(Object.assign({}, this.initialContext(), {formComponents: this.formComponents()}));
		this.fieldInstance = this.context().form.getFieldInstance(this.formComponent.field);
		this.value = FormsClient.psFormKoObservable({observable: this.fieldInstance.value, ref:null, owner:this});
		this.valid = FormsClient.psFormKoObservable({observable: this.fieldInstance.valid, ref:null, owner:this});
		this.messages = FormsClient.psFormKoObservable({observable: this.fieldInstance.messages, ref:null, owner:this});
		this.enabled = FormsClient.psFormKoObservable({observable: this.fieldInstance.enabled, ref:null, owner:this});
		this.visible = FormsClient.psFormKoObservable({observable: this.fieldInstance.visible, ref:null, owner:this});
		this.fieldMessages = ko.computed(()=>{
			let messages = this.messages();
			if (messages.length && messages.length > 0)
				return JSON.stringify(messages);
			return '';
		})

		this.fieldProps = this.fieldInstance.field.getObservableRef('properties');
		// TODO initialize if contains some items on initialization
		// for each item in the array do a form control component
		console.log(this.fieldProps());
		// this.fieldProps.subscribe((newValue)=>{
		// 	//rebuild layout
		// 	console.log(newValue);
		// 	console.log(this.formComponent);
		// 	if (Array.isArray(this.formComponent.items)){

		// 	} else {
		// 		//
		// 		let properties = [];
		// 		console.log(this.fieldInstance.fieldInstances)
		// 		for (let fieldInstance of this.fieldInstance.fieldInstances){
		// 			console.log(fieldInstance);
		// 			properties.push(Object.assign({}, this.formComponent.items));
		// 		}
		// 		console.log(properties);
		// 		this.formComponents(properties);
		// 		console.log(this.context().form.observable());
		// 	}
		// })
		this.buildList();
	}

	generateIndex(index){
		let indexes = [].concat(this.context().index);
		indexes.push(index);
		return indexes;
	}

	buildList(){
		// TODO update working definition with properties entries?
		let properties = [];
		console.log(this.fieldInstance.fieldInstances)
		for (let index in this.fieldInstance.fieldInstances){
			console.log(index);
			let items = JSON.parse(JSON.stringify(this.formComponent.items));
			properties.push(items);
		}
		console.log(properties);
		this.formComponents(properties);
	}

	addRow(){
		// console.log('#############',JSON.stringify(this.data_model_value()[0]));
		// this.data_model_value.push(JSON.parse(JSON.stringify(this.dataTemplate())));
		// // console.log('add row:',this.formData.form());
		// this.rows(this.data_model_value());
		this.fieldInstance.addItem();
		this.buildList();

	}

	removeRow(index, vm){ //TODO fix remove and update rows properly
		// remove instance
		this.fieldInstance.fieldInstances.splice(index, 1);
		this.buildList();
	}

};

window.FormsClient.registerUIComponent({type:'list',ui_component:{ name:'ko-list', schema:{}}});

export default {
	name: 'ko-list',
	viewModel: ComponentVM,
	module_type: 'ko',
	template: template
};
